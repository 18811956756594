<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-else class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/moneymovementsheet')">Ведомость начислений/выплат</h3>
      <h3 class="pointer" @click="followLink('/personal')">Смены сотрудника списком</h3>
      <h3 class="pointer" @click="followLink('/salary')">
        Начисленная зарплата бариста суммарно или по каждой точке
      </h3>
      <h3 v-if="!!user.brew" class="pointer" @click="followLink('/salarybypandp')">
        Начисленная зарплата бариста с разбивкой по точкам (часы/деньги)
      </h3>
      <h3 v-if="!!user.brew" class="pointer" @click="followLink('/salaryaverage')">Средняя зарплата</h3>
      <h3 v-if="!!user.brew" class="pointer" @click="followLink('/summary')">
        ФОТ на каждую точку (только смены)
      </h3>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue2-helpers/vue-router";
import { userDataGet } from "@/components-js/requestSrv";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: "Reports",
  components: { Loader },
  setup() {
    const router = useRouter();
    const user = ref({});
    const loading = ref(true);
    const followLink = href => {
      router.push(href);
    };

    onMounted(async () => {
      user.value = await userDataGet();
      loading.value = false;
    });
    return {
      user,
      loading,
      followLink,
    };
  },
});
</script>
